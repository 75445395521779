<template>
  <PopoverSelector
    v-if="hasRole(RoleEnum.ACCESS_TESTPORTAL)"
    v-model="isOpen"
    :popover-title="$t('navigation.SELECT_APPLICATION')"
    :popover-content-width="254"
  >
    <template #button>
      <div class="layout">
        <template v-if="system.selectedTool === Tool.enum.TESTPORTAL">
          <LogoTestportalWord />
          <span class="sr-only">{{ $t('navigation.selectedTool', { tool: 'Test Portal' }) }}</span>
        </template>
        <template v-else>
          <LogoExactWord />
          <span class="sr-only">{{ $t('navigation.selectedTool', { tool: 'exact' }) }}</span>
        </template>
      </div>
    </template>

    <div class="selector-body">
      <Btn unstyled style="width: 100%" @click="onToolSelection(Tool.enum.EXACT)">
        <div class="selector-item">
          <LogoExactWord />
          <span class="sr-only">exact</span>
        </div>
      </Btn>
      <Btn unstyled style="width: 100%" @click="onToolSelection(Tool.enum.TESTPORTAL)">
        <div class="selector-item">
          <LogoTestportalWord />
          <span class="sr-only">Test Portal</span>
        </div>
      </Btn>
    </div>
  </PopoverSelector>
  <h1 v-else>
    <a :href="BASE_URL">
      <LogoExactWord />
      <span class="sr-only">exact</span>
    </a>
  </h1>
</template>

<script setup lang="ts">
import Btn from '@/components/Btn.vue';
import PopoverSelector from '@/components/PopoverSelector.vue';
import LogoExactWord from '@/components/UI/logo/ExactWord.vue';
import LogoTestportalWord from '@/components/UI/logo/TestportalWord.vue';
import { Tool } from '@/models/tool/Tool';
import { RoleEnum } from '@/models/user/RoleEnum';
import { DASHBOARD, TESTPORTAL } from '@/router/route-types';
import { useSystemStore } from '@/stores/system';
import { hasRole } from '@/utils/roles';

const BASE_URL = import.meta.env.BASE_URL;

const system = useSystemStore();
const router = useRouter();

const isOpen = ref(false);

const onToolSelection = (tool: Tool) => {
  isOpen.value = false;
  if (system.selectedTool === tool) return;
  system.setSelectedTool(tool);

  switch (tool) {
    case Tool.enum.EXACT:
      router.push({ name: DASHBOARD });
      return;

    case Tool.enum.TESTPORTAL:
      router.push({ name: TESTPORTAL });
  }
};
</script>

<style scoped lang="scss">
@use '@/assets/theme/variables';

h1 {
  width: variables.$sidebar-width;

  margin: 0;
  padding: 0;
  line-height: 0;

  a {
    width: 100%;
    height: 100%;
    padding-left: 26px;

    display: flex;
    align-items: center;
  }
}

.logo-exact-word {
  color: var(--color-primary);
}

.logo-testportal-word {
  color: var(--color-ebony);
}

.layout {
  width: variables.$sidebar-width;
  padding-left: 26px;

  flex-shrink: 0;

  display: flex;
  align-items: center;
}

.selector-body {
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;

  .selector-item {
    cursor: pointer;
    width: 100%;
    height: 56px;

    border-radius: 4px;
    border: 1px solid var(--color-background-light);

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &:hover {
      background-color: var(--color-background-light);
    }

    .logo-exact-word {
      width: 88px;
      height: 24px;
    }
  }
}
</style>
